<template>
  <div class="sign-up" ref="signIn">
    <q-card class="sign-up__card --emphasize">
      <q-card-section v-if="!submitted">
        <h5 class="q-pb-xs text-weight-medium">
          Create Your Account
        </h5>
        <!--<p class="q-mb-none text-font-secondary">
          Complete the sign up form below to get started as an advertiser. <br /> Once completed, a member of our team will contact you.
        </p>-->
      </q-card-section>

      <q-card-section v-if="!submitted" class="q-pt-sm">
        <q-form class="sign-up__form" @submit="validateLogin">
          <div class="row q-col-gutter-y-sm q-col-gutter-x-md">
            <div class="col-12 q-mt-ms">
              <p class="text-weight-medium">
                User Information
              </p>
              <p class="q-mb-none text-font-secondary">
                This information will be used to create your user account.
              </p>
            </div>
            <div class="col-6">
              <q-input
                filled
                v-model="formData.firstName"
                label="First Name"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 1) || 'Please enter your First Name.'
                ]"
                :disable="submitting"
              />
            </div>

            <div class="col-6">
              <q-input
                filled
                v-model="formData.lastName"
                label="Last Name"
                lazy-rules
                :rules="[
                val =>
                    (val && val.length > 1) || 'Please enter your Last Name.'
                ]"
                :disable="submitting"
              />
            </div>

            <div class="col-12">
              <q-input
                filled
                v-model="formData.email"
                label="Email Address"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 0) ||
                    'Please enter your Email Address.'
                ]"
                :disable="submitting"
              />
            </div>

            <div class="col-12">
              <q-input
                filled
                v-model="formData.phone"
                label="Phone Number"
                lazy-rules
                :rules="[
                val =>
                  (val && val.length > 0) ||
                  'Please enter your Email Address.'
                ]"
                :disable="submitting"
              />
            </div>

            <div class="col-12 q-mt-ms">
              <p class="text-weight-medium">
                Business Information
              </p>
              <p class="q-mb-none text-font-secondary">
                Some basic information about your business.
              </p>
            </div>
            <div class="col-6">
              <q-input
                  filled
                  v-model="formData.businessName"
                  label="Business Name"
                  lazy-rules
                  :rules="[
                val =>
                  (val && val.length > 1) ||
                  'Please enter your Business Name.'
                ]"
                  :disable="submitting"
              />
            </div>
            <div class="col-6">
              <q-select
                  filled
                  v-model="formData.businessType"
                  :options="[
                  {
                    label: 'Dealership',
                    value: 'dealership'
                  },
                  {
                    label: 'Online Marketplace (Aggregator)',
                    value: 'online_marketplace'
                  },
                  {
                    label: 'Agency',
                    value: 'agency'
                  },
                  {
                    label: 'Other',
                    value: 'other'
                  }
                ]"
                  label="Business Type"
                  emit-value
                  map-options
                  :rules="[
                val =>
                  (val && val.length > 0) ||
                  'Please select your Business Type.'
                ]"
                  :disable="submitting"
              />
            </div>
          </div>

          <div v-if="invalidSubmissionMessage.length > 0" class="text-negative">
            {{ invalidSubmissionMessage }}
          </div>

          <div class="sign-up__nav-container q-mt-sm">
            <router-link
              class="text-subtle"
              :to="{ name: 'SignIn' }"
            >
              Already have an account? Sign In
            </router-link>
            <q-btn
              label="Sign Up"
              type="submit"
              color="primary"
              :disable="submitting"
              :loading="submitting"
            />
          </div>
        </q-form>
      </q-card-section>
      <q-card-section v-else class="column items-center justify-center q-pt-sm text-center">
        <q-icon
          color="positive"
          size="60px"
          name="sym_r_check_circle"
          class="q-my-sm --icon-filled"
        />
        <!--<h5 class="text-weight-medium">
          Advertiser Sign Up Successful
        </h5>-->
        <p class="text-font-secondary q-pt-sm">
          You're all set! We're reviewing your submission. <br> Upon approval, you will receive a verification email at <b>{{ formData.email }}</b> and gain access to CarScience.
        </p>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "SignUp",
  data() {
    return {
      formData: {
        businessName: "",
        businessType: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      submitting: false,
      submitted: false,
      invalidSubmissionMessage: ""
    };
  },
  methods: {
    validateLogin() {
      this.submitting = true;
      this.invalidSubmissionMessage = "";

      this.$store
        .dispatch("business/POST_REQUEST", {
          endpoint: "sign-up",
          params: {
            name: this.formData.businessName,
            type: this.formData.businessType,
            user_first_name: this.formData.firstName,
            user_last_name: this.formData.lastName,
            user_email: this.formData.email,
            user_phone: this.formData.phone,
            advertiser_enabled: 1
          }
        })
        .then(() => {
          this.submitted = true;
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
                "submit",
                false,
                e.response.data.errors[0].detail
            );
            this.invalidSubmissionMessage = e.response.data.errors[0].detail;
          } else {
            this.triggerActionOutcomeAlert("submit", false);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-up {
  @include display-flex(column, center, center);
  @include fullscreen-height-w-titlebar();

  .sign-up__card {
    width: min(650px, 100vw - #{2 * $space-md});
  }

  .sign-up__nav-container {
    @include display-flex(row, space-between, center);
  }
}
</style>
